.product-tabel2 {
 thead {
    border-color: transparent !important;
    border-top-color: black !important;
    border-bottom: 1.5px solid black !important;
    border-width: 1.5px !important;
    border-style: solid !important;
  }

  table {
    caption-side: bottom;
    border-collapse: collapse !important;
  }

  .header-label {
    font-family: 'Encode Sans Condensed', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #000000;
  }

 .content-label {
    font-family: 'Encode Sans Condensed', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 0px !important;
    color: #000000;
  }

  th {
    color: #000000 !important;
    font-family: 'Encode Sans Condensed', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 0px !important;
  }

  .disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
    pointer-events: none;
}
}
