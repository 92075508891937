.footer-desktop {
  @media only screen and (max-width: 911px) {
    display: none !important;
  }
}

.footer-mobile {
  @media only screen and (min-width: 910px) {
    display: none !important;
  }
}