.burgundy-map {
  p {
    img {
      text-align: center !important;
    }
  }
  span {
    img {
      text-align: center;
    }
  }
  .card-wrapper {
    display: flex !important;
    justify-content: center;
    flex-direction: column;
    align-content: center;
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    border-color: white !important;
    border-bottom: 3px solid #cda257 !important;
    font-family: 'Encode Sans Condensed', serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    background-color: transparent !important;
    color: #cda257 !important;
    opacity: 1;
  }

  .nav-tabs {
    border-bottom: none !important;
    width: 1470px;
    background-color: #f5f5f5;
    --bs-nav-tabs-border-width: 0px !important;
    @media only screen and (max-width: 600px) {
      width: unset;
    }
    @media only screen and (max-width: 1536px) {
      width: 100%;
    }
  }

  .nav-tabs .nav-link {
    margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
    background: 0 0;
    border-color: white !important;
    border-top-left-radius: var(--bs-nav-tabs-border-radius);
    border-top-right-radius: var(--bs-nav-tabs-border-radius);
    font-family: 'Encode Sans Condensed', serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #cda257;
    opacity: 0.5;
  }

  .nav {
    --bs-nav-link-padding-x: 1rem;
    --bs-nav-link-padding-y: 1rem;
    --bs-nav-link-color: none !important;
    --bs-nav-link-hover-color: none !important;
    --bs-nav-link-disabled-color: #6c757d;
    display: flex;
    flex-wrap: wrap;
    padding: 20px 30px;
    margin-bottom: 0;
    list-style: none;
  }

  .form-check-input:checked {
    background-color: #3e2f24 !important;
    border-color: #3e2f24 !important;
  }
}
