.account-setting {
  .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    background-color: var(--bs-nav-tabs-link-active-bg);
    border-color: white !important;
    border-bottom: 1px solid black !important;
    font-family: 'ABeeZee', serif;
    /* font-style: italic; */
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #CD5D78 !important;
    opacity: 0.5;
  }

  .nav-tabs {
    border-bottom: none !important;
  }

  .nav-tabs .nav-link {
    margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
    background: 0 0;
    border-color: white !important;
    border-top-left-radius: var(--bs-nav-tabs-border-radius);
    border-top-right-radius: var(--bs-nav-tabs-border-radius);
    font-family: 'ABeeZee', serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #CD5D78;
  }

  .nav {
    --bs-nav-link-padding-x: 1rem;
    --bs-nav-link-padding-y: 0.5rem;
    --bs-nav-link-color: none !important;
    --bs-nav-link-hover-color: none !important;
    --bs-nav-link-disabled-color: #6c757d;
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }

  .form-check-input:checked {
    background-color: #3E2F24 !important;
    border-color: #3E2F24 !important;
  }
}
