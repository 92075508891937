.disabled {
    opacity: 0.7;
    background: #cda257;
    cursor: not-allowed !important;
    pointer-events: none;
}
.error-message {
    color: red;
    font-size: 10px;
    margin-top: -10px;
    padding: 10px 0 5px 0;
}