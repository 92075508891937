.menu-no-border {
  border-right: none !important;
  border-inline-end: none !important;
  padding-top: 0px;
}

.hide-arrow {
  .ant-menu-submenu-arrow {
    display: none !important;
  }
}

.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 0 !important;
}

.ant-collapse .ant-collapse-content > .ant-collapse-content-box {
  padding: 0px !important;
}

.ant-menu-light .ant-menu-item-selected {
  background-color: rgba(205, 162, 87, 0.1);
}

.ant-menu-light .ant-menu-item-selected {
  color: #cda257 !important;
}

.ant-menu-light .ant-menu-submenu-selected > .ant-menu-submenu-title {
  color: black !important;
}

.small-radio-label .MuiSvgIcon-root {
  font-size: 18px; /* Adjust the size as per your preference */
}

.small-radio-label .MuiFormControlLabel-label {
  font-size: 14px; /* Adjust the size as per your preference */
  font-weight: 500;
}
