.product-tabel {
  thead {
    border-color: transparent !important;
    border-top-color: black !important;
    border-bottom: 1.5px solid black !important;
    border-width: 1.5px !important;
    border-style: solid !important;
  }

  table {
    caption-side: bottom;
    border-collapse: collapse !important;
  }

  .header-label {
    font-family: 'Encode Sans Condensed', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #000000;
  }

  .content-label {
    font-family: 'Encode Sans Condensed', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 0px !important;
    color: #000000;
  }

  th {
    color: #000000 !important;
    font-family: 'Encode Sans Condensed', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 0px !important;
  }

  .active {
    background-color: #cda257 !important;
    color: white !important;
  }
  .filter-icon {
    padding: 3px;
    font-size: 25px !important;
    color: #b6b6b6;
  }
  .disabled {
    opacity: 0.7;
    background: #cda257;
    cursor: not-allowed !important;
    pointer-events: none;
}
}
